<style lang="scss">
@import "~@/assets/css/var";

.cs {
  display: flex;
  position: relative;
  height: 600px;
  margin: -10px -20px;

  &-dialog.my-dialog {
    .el-dialog {
      border-radius: 15px;
      overflow: hidden;
    }

    .el-dialog__header {
      background: #f0f2f5;
      border-bottom: 1px solid #d8d8d8;
    }

    .el-dialog__body {
      background: #f0f2f5;
    }

    .el-dialog__title {
      color: #4f4f4f;
    }

    .el-dialog__close {
      color: #4f4f4f;
      font-size: 20px;
      font-weight: bold;

      &:hover {
        opacity: 0.7;
        color: $red;
      }
    }
  }

  &-right {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__voice {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 189px;
      background: #F0F2F5;
      z-index: 100;
    }


    &__history {
      flex: 1;
      overflow-y: auto;
      padding-bottom: 10px;
    }

    &__assist {
      display: flex;
      align-items: center;
      border-top: 1px solid #ddd;
      font-size: 20px;
      padding: 0 $border;
      height: 40px;
      line-height: 40px;

      i {
        cursor: pointer;
        margin-left: 15px;

        &:hover {
          color: #000;
        }
      }
    }

    &__write {
      textarea {
        font-size: 14px;
        border: 0;
        resize: none;
        background: transparent;
      }
    }

    &__sure {
      padding: 10px 20px;
      // text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .msg {
        display: flex;
        align-items: center;
        background: #d9d9d9;
        border-radius: 3px;
        font-size: 12px;
        padding: 1px 10px;
        white-space: nowrap;
      }

      .tag {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
      }
    }

    &__item {
      display: flex;
      padding: 10px 10px 0;

      .qu_text {
        display: flex;
        justify-content: center;

        .qu_text_btn {
          color: #00aaff;
          margin-left: 10px;
        }
      }

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 10px 10px 0 0;
      }

      .msg {
        align-items: flex-start;
      }

      &.self {
        justify-content: flex-end;

        .tag {
          background: #95ec69;
        }

        .avatar {
          order: 2;
          margin: 10px 0 0 10px;
        }

        .msg {
          text-align: right;
          align-items: flex-end;
        }
      }

      .tag {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 5px;
        background: #fff;
        color: #000;
        max-width: 350px;
        font-size: 0;
        white-space: pre;
        text-align: left;
        text-wrap: wrap;
        box-shadow: 5px 3px 10px -4px #63645e;

        .content-audio {
          word-break: break-all;
          font-size: 28rpx;
          text-align: left;
          white-space: break-spaces;
          display: flex;
          align-items: center;
        }

        img {
          cursor: pointer;
        }

        .small {
          max-width: 100px;
          max-height: 100px;
        }
      }

      .quote_txt {
        font-size: 12px;
        color: #858181;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
        display: flex;
        align-items: center;
      }

      .quote_txt_thumb {
        width: 30px;
        height: 20px;
        border-radius: 4px;

        .icon {
          font-size: 16px;
        }

        img {
          margin: unset;
        }
      }

      .display_flex {
        display: flex;
        align-items: center;
      }

      .time {
        font-size: 12px;
        color: #999;
        line-height: 24px;
      }
    }
  }
}

.el-image-viewer__canvas img {
  width: unset;
}

.custom-popover__content {
  padding-top: 20px;
  text-align: center;

  .big {
    width: 80%;
    height: 80%;
  }
}

.rich-text {
  font-size: 12px;
  white-space: pre-wrap;

  img {
    max-width: 100%;
  }
}
</style>

<template>
  <div>
    <ts-dialog class="cs-dialog" :show.sync="show" title="淘数网" hide-footer hide-modal closeOnClickModal width="700px">
      <template #title>
        <span class="el-dialog__title">淘数网客服</span>
        <span style="margin: 0 20px 0 10px; color: #999">早上(9:00-12:00) 下午(13:30-17:30)</span>
        <el-popover placement="right-start" width="200" trigger="hover" :open-delay="350">
          <div class="custom-popover__content">
            <el-image class="big" :src="picUrl" fit="fill"></el-image>
            <div class="desc">扫码加我微信</div>
            <div class="list" @click="onContact(child)">
              <div class="mode">联系电话：</div>
              <div class="number">400-8676768</div>
            </div>
            <div class="list" @click="onContact(child)">
              <div class="mode">联系QQ：</div>
              <div class="number">2376201058</div>
            </div>
          </div>
          <el-button slot="reference" type="text" icon="el-icon-chat-line-round">联系方式</el-button>
        </el-popover>
        <template v-if="!$help.getCookie('myCookie')">
          <el-button type="text" style="float: right;margin-right: 40px;" @click="onTransfer" :loading="isTransfer">
            转人工
            <span>(智能客服中...)</span>
          </el-button>
        </template>
        <template v-else>
          <el-button type="text" style="float: right;margin-right: 40px;">
            <span>(人工客服中...)</span>
          </el-button>
        </template>
      </template>

      <div class="cs">
        <div class="cs-right">
          <div ref="history" class="cs-right__history">
            <div class="flex flex-center flex-column" style="height: 40px; justify-content: center">
              <div class="cs-right__item" style="text-align: center" v-if="loading">
                <i class="el-icon-loading" style="line-height: 22px"></i>加载中...
              </div>
              <div class="cs-right__item load-more" style="text-align: center" v-show="!loading && !finished">
                <el-button type="text" @click="loadNext(true)">更多记录</el-button>
              </div>
              <div v-show="!loading && finished" class="cs-right__item" style="text-align: center">
                到顶了
              </div>
            </div>
            <div class="cs-right__item" v-for="item in list" :key="item.id" :class="{ self: item.isMe }">
              <template v-if="item.withdraw == 1">
                <div style="width: 100%; text-align: center; font-size: 12px">
                  <div>
                    {{ item.sendTime }}
                  </div>
                  <div class="qu_text" v-if="item.isMe">你撤回了一条消息
                    <div class="qu_text_btn" v-if="item.messageType === 'TEXT' && newTime(item.sendTime)"
                      @click="reEdit(item)">重新编辑</div>
                  </div>
                  <div v-else-if="item.eventType === 'TRANSFER_MESSAGE'">{{ item.content }}</div>
                  <div v-else>"客服" 撤回了一条消息</div>
                </div>
              </template>
              <template v-else>
                <template v-if="item.isMe">
                  <img v-if="$store.state.user.picUrl" class="avatar" :src="$store.state.user.picUrl" alt="用户头像" />
                  <img v-else class="avatar" src="@/assets/img/avatar-user.jpg" alt="用户头像" />
                </template>
                <img v-else class="avatar" src="@/assets/img/avatar-cs.jpg" alt="客服头像" />

                <div class="msg" style="display: flex; flex-direction: column">
                  <div class="time">{{ item.sendTime }}</div>
                  <div class="tag" @contextmenu.prevent="showContextMenu($event, item)">
                    <template v-if="item.messageType === 'IMAGE'">
                      <el-image class="small" :src="item.content" :preview-src-list="[item.content]"></el-image>
                    </template>
                    <div ref="RICHTEXT" class="rich-text" @click="handleHtml($event)"
                      v-else-if="item.messageType === 'RICHTEXT'" v-html="item.content"></div>
                    <el-link v-else-if="item.messageType === 'FILEURL'" size="mini" target="_blank" type="primary"
                      style="font-size: 12px" :href="item.content" :underline="false">{{ item.content | setFileText
                      }}</el-link>
                    <div v-else-if="item.messageType === 'VOICE'" @click="playVoice(item)" class="content-audio">
                      <a-trumpent v-if="!item.isMe" :isPlay="item.isPlay"></a-trumpent>
                      <span style="font-size: 12px;">{{ item.time || '' }}"</span>
                      <a-trumpent v-if="item.isMe" :isPlay="item.isPlay" :direction="'left'"></a-trumpent>
                    </div>
                    <div style="font-size: 12px" v-else>{{ item.content }}</div>

                  </div>
                  <div class="tag" style="background: #e3e3e3; margin-top: 5px"
                    v-if="item.messageType === 'VOICE' && item.convertText && item.isDisplayText">
                    <div class="quote_txt">
                      {{ item.convertText }}
                    </div>
                  </div>
                  <div class="tag" style="background: #e3e3e3; margin-top: 5px" v-if="item.quoteMessage">
                    <div class="quote_txt">
                      {{ setQuoteMessage(JSON.parse(item.quoteMessage)) }}
                      ：
                      <template v-if="JSON.parse(item.quoteMessage).messageType === 'TEXT'">
                        {{ JSON.parse(item.quoteMessage).content }}
                      </template>
                      <template v-if="JSON.parse(item.quoteMessage).messageType === 'IMAGE'">
                        <el-image class="quote_txt_thumb" :src="JSON.parse(item.quoteMessage).content"
                          :preview-src-list="[JSON.parse(item.quoteMessage).content,]">
                        </el-image>
                      </template>
                      <template v-if="JSON.parse(item.quoteMessage).messageType === 'FILEURL'">
                        {{ JSON.parse(item.quoteMessage).content | setFileText }}
                      </template>
                      <template v-if="JSON.parse(item.quoteMessage).messageType === 'VOICE'">
                        <div class="quote_txt_thumb display_flex"
                          @click="playVoice(JSON.parse(item.quoteMessage), 'quoteMessage')">
                          <a-trumpent v-if="!item.isMe" :isPlay="JSON.parse(item.quoteMessage).isPlay"
                            color="#858181"></a-trumpent>
                          <span style="font-size: 12px;">{{ JSON.parse(item.quoteMessage).time || '' }}"</span>
                          <a-trumpent v-if="item.isMe" :isPlay="JSON.parse(item.quoteMessage).isPlay"
                            :direction="'left'" color="#858181"></a-trumpent>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="cs-right__voice" v-if="isVoice">
            <play-audio :isVoice="isVoice" @setIsVoice="setIsVoice" @uploadAudio="uploadAudio"></play-audio>
          </div>
          <div class="cs-right__assist">
            <ts-face @input="addFace"></ts-face>
            <i class="icon icon-img" style="margin-top: 3px" @click="goUploadImg"></i>
            <i class="el-icon-folder-opened" @click="goUploadFile"></i>
            <i class=" icon icon-mic-on" @click="isVoice = true"></i>
          </div>
          <el-input class="cs-right__write" type="textarea" :rows="4" v-model="txt" @keydown.enter.native="inputEnter"
            @paste.native="pasteContent"></el-input>
          <div class="cs-right__sure">
            <div style="max-width: 400px">
              <div class="msg" v-if="JSON.stringify(quoteObj) !== '{}'">
                {{ quoteObj.onMe ? "自己" : '客服' }}：
                <span class="tag">
                  <template v-if="quoteObj.messageType === 'IMAGE'">[图片]</template>
                  <template v-else-if="quoteObj.messageType === 'FILEURL'">{{ quoteObj.content | setFileText
                    }}</template>
                  <template v-else-if="quoteObj.messageType === 'VOICE'">语音{{ quoteObj.voiceTime }}”</template>
                  <template v-else>{{ quoteObj.content }}</template>
                </span>
                <i class="el-icon-close" style="margin-top: 3px; padding: 5px; cursor: pointer"
                  @click="quoteObj = {}"></i>
              </div>
            </div>
            <el-dropdown split-button size="medium" type="success" @click="sendMsg" :loading="sendLoading"
              @command="handleCommand">
              {{ sendLoading ? "正在发送..." : `发送（${isEnter === 'Enter' ? "Enter" : "Ctrl + Enter"}）` }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Enter" :icon="isEnter === 'Enter' ? 'el-icon-check' : ''">按 Enter
                  键发送消息</el-dropdown-item>
                <el-dropdown-item command="ctrlEnter" :icon="isEnter === 'ctrlEnter' ? 'el-icon-check' : ''">按 Ctrl +
                  Enter
                  键发送消息</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <ts-upload ref="upload" style="display: none" @changeImg="sendImg" @changeFile="sendFile"
          @begin="sendLoading = true" :typeFile="typeFile">
        </ts-upload>

        <el-dialog :visible.sync="imgPopup.show" title="确认发送吗？" width="500px" destroy-on-close append-to-body>
          <div style="text-align: center">
            <el-image :src="imgPopup.url" fit="container"></el-image>
          </div>
          <div style="margin-top: 15px; text-align: right">
            <el-button type="primary" @click="confirmImg" :loading="imgPopup.loading">发送</el-button>
          </div>
        </el-dialog>
      </div>
    </ts-dialog>
    <context-menu v-if="menuVisible" :top="menuTop" :left="menuLeft" :menuObj="menuObj" @close="hideContextMenu" />

    <el-image v-if="isImgShow" ref="elImage" style="width: 0; height: 0;" :src="bigImageUrl"
      :preview-src-list="[bigImageUrl]">
    </el-image>
    <audio ref="audio" class="aud" @ended="onAudioEnded">
      <source src="" />
    </audio>
  </div>
</template>

<script>
// 客服组件

import help from "@/util/help";
import qiniu from "@/util/qiniu";
import ContextMenu from "./ContextMenu.vue";
import PlayAudio from './PlayAudio.vue';
import aTrumpent from './aTrumpent.vue';


export default {
  components: { ContextMenu, PlayAudio, aTrumpent },
  props: {
    unSocket: {
      typeof: Boolean,
      default: true,
    },
  },
  data() {
    return {
      picUrl: require("@/assets/img/wechat1.png"),

      isVoice: false, //是否语音

      show: false,
      txt: "",

      typeFile: 'img',   //上传类型

      ws: null,
      list: [],
      loading: false,
      finished: false,
      firstLoad: false,

      sendLoading: false,

      fromNow: [],
      unReadNumbers: 0,

      customerList: [],

      clientId: help.getClientId(),

      imgPopup: {
        show: false,
        url: "",
        loading: false,
      },

      menuVisible: false,
      menuTop: 0,
      menuLeft: 0,
      menuObj: {}, //双击的消息对象
      quoteObj: {}, //引用的消息对象
      userOid: this.$store.state.user.id,

      isEnter: '',
      isTransfer: false,

      bigImageUrl: '', //图片预览
      isImgShow: false, //是否显示大图


      newRow: {},
      newCurrentTime: 0,
      setIntervalTime: '',
      newQuoteMessage: '',
    };
  },
  watch: {
    unReadNumbers(val) {
      this.$store.commit("setUnReadCount", val);
    },
    show(val) {
      if (!val) this.quoteObj = {};
    },
  },
  filters: {
    setFileText(val) {
      let str = ''
      if (val) {
        const index = val.lastIndexOf("/");
        str = val.substr(index + 1);
      }
      return str
    },
  },
  created() {
    this.isEnter = this.$help.store.get('sendingMethod')
    // 加载未读信息
    this.$api
      .get(
        `sys/SysChatMessages/queryCustomerServiceOnlineAndUnReadNumbers?clientId=${this.clientId}`
      )
      .then((res) => {
        this.unReadNumbers = res.data.unReadNumbers;
      });
    if (this.unSocket) {
      this.initSocket();
    }
    window.reSocket = () => {
      this.reSocket();
    };
    window.newSocket = () => {
      this.newSocket();
    };
  },
  methods: {
    //播放录音
    playVoice(orr, text = "") {
      this.clearInterval()
      if (JSON.stringify(this.newRow) !== '{}' && orr.id !== this.newRow.id) {
        this.newCurrentTime = 0
        this.$refs.audio.currentTime = this.newCurrentTime
        this.$refs.audio.load();
        this.newRow.isPlay = false
        this.setNewList(this.newCurrentTime)
      }
      this.newQuoteMessage = text
      if (JSON.stringify(this.newRow) === '{}' || orr.id !== this.newRow.id) {
        this.newRow = this.$help.deepClone(orr)
        this.newCurrentTime = 0
      }
      this.$refs.audio.src = this.newRow.content;
      if (this.newRow.isPlay) {
        this.$refs.audio.pause();
        this.newRow.isPlay = false
        this.setNewList(this.newCurrentTime)
      } else {
        this.$refs.audio.currentTime = this.newCurrentTime
        this.$refs.audio.play().catch(() => {
          this.$refs.audio.play()
        })
        this.newRow.isPlay = true
        this.setIntervalTime = setInterval(() => {
          this.newCurrentTime = this.$refs.audio.currentTime
          if (this.newRow.time <= 0 || Math.round(this.newCurrentTime) === this.newRow.voiceTime || this.$refs.audio.currentTime === 0) {
            this.clearInterval()
            this.newCurrentTime = 0
            this.newRow.isPlay = false
            this.setNewList(this.newCurrentTime)
          }
          this.setNewList(this.newCurrentTime)
        }, 1000)
      }
    },
    clearInterval() {
      this.$refs.audio.pause();
      clearInterval(this.setIntervalTime)
      this.setIntervalTime = ''
    },
    //结束播放语音
    onAudioEnded() {
      this.clearInterval()
      this.$refs.audio.load();
      this.newCurrentTime = 0
      this.newRow.isPlay = false
      this.setNewList(this.newCurrentTime)
    },
    setNewList(time) {
      if (time > 0) {
        this.newRow.time = this.newRow.voiceTime - Math.round(time)
      } else {
        this.newRow.time = this.newRow.voiceTime
      }
      if (this.newQuoteMessage === 'quoteMessage') {
        this.list = this.list.map(item => {
          if (item.quoteMessage && JSON.parse(item.quoteMessage).id === this.newRow.id) {
            item.quoteMessage = JSON.stringify(this.newRow)
          } else {
            if (item.messageType === 'VOICE') {
              item.time = item.voiceTime
              item.isPlay = false
            }
            if (item.quoteMessage && JSON.stringify(item.quoteMessage) !== '{}') {
              const _obj = JSON.parse(item.quoteMessage)
              if (_obj.messageType === 'VOICE') {
                _obj.time = _obj.voiceTime
                _obj.isPlay = false
                item.quoteMessage = JSON.stringify(_obj)
              }
            }
          }
          return item
        })
      } else {
        const index = this.list.findIndex((item) => { return item.id === this.newRow.id })
        this.list.splice(index, 1, this.newRow)

        this.list = this.list.map(item => {
          if (item.id !== this.newRow.id && item.messageType === 'VOICE') {
            item.time = item.voiceTime
            item.isPlay = false
          }
          if (item.quoteMessage && JSON.stringify(item.quoteMessage) !== '{}') {
            const _obj = JSON.parse(item.quoteMessage)
            if (_obj.messageType === 'VOICE') {
              _obj.time = _obj.voiceTime
              _obj.isPlay = false
              item.quoteMessage = JSON.stringify(_obj)
            }
          }
          return item
        })
      }

    },

    //上传语音成功
    uploadAudio(url, time) {
      this.sendVoice(url, time)
    },
    //语音弹窗
    setIsVoice(val) {
      this.isVoice = val
    },
    //富文本点击
    handleHtml(e) {
      if (e.target && e.target.nodeName === 'A' && e.target.getAttribute('href') !== 'javascript:void(0);') {
        e.preventDefault();
        let href = e.target.getAttribute('href');
        window.open(href, '_blank');
      } else if (e.target && e.target.nodeName === 'IMG') {
        e.preventDefault();
        this.isImgShow = true
        this.bigImageUrl = e.target.src
        this.$nextTick(() => {
          this.$refs.elImage.clickHandler()
        })
      }
    },
    goArtificial() {
      this.ws.send(
        JSON.stringify({
          eventType: "TRANSFER_MESSAGE",
          serviceType: '2',
          toClientId: this.clientId || "",
          toUserId: this.$store.state.user.id || "",
        })
      );
    },
    //转人工
    onTransfer() {
      // console.log('获取cookie', help.getCookie('myCookie'))
      if (!help.getCookie('myCookie')) {
        this.isTransfer = true
        this.goArtificial()
      } else {
        this.$store.commit('error', '您已转人工，请勿重复操作')
      }

    },
    //控制5分钟后把重新编辑关掉
    newTime(time) {
      let end = new Date().getTime();
      let start = new Date(time).getTime();
      let utc = end - start;
      let m = utc / (60 * 1000)
      return m < 5
    },
    setQuoteMessage(val) {
      if (this.userOid === val.sendUserId || this.clientId === val.sendClientId) {
        return '自己'
      } else {
        return '客服'
      }
    },
    //右击弹窗
    showContextMenu(event, message) {
      if (message.fromUser && message.fromUser.userCode !== "TS15397914521") {
        this.menuVisible = true;
        this.menuTop = event.clientY + "px";
        this.menuLeft = event.clientX + "px";
        this.menuObj = message;
      }
    },
    hideContextMenu(type, obj) {
      this.menuVisible = false;
      if (type === 1) {
        //撤回
        this.list.map((item) => {
          if (item.id === obj.id) {
            this.$set(item, "withdraw", 1);
          }
          return item;
        });
      } else if (type === 2) {
        //引用
        this.quoteObj = obj;
        this.quoteObj.onMe = this.userOid === this.quoteObj.fromUserId || this.clientId === this.quoteObj.fromClientId;
      } else if (type === 3) {
        //转文字
        this.list.map((item, i) => {
          if (item.id === obj.id) {
            this.list.splice(i, 1, obj)
            if (i === this.list.length - 1) {
              this.setScrollBottom()
            }
          }
          return item;
        })
      }
    },
    //重新编辑
    reEdit(obj) {
      this.txt = obj.content
      if (obj.quoteMessage) {
        this.quoteObj = JSON.parse(obj.quoteMessage)
        this.quoteObj.onMe = this.userOid === this.quoteObj.sendUserId || this.clientId === this.quoteObj.sendClientId;
      }
    },
    //联系
    onContact(data) {
      if (data.type === "tel") {
        window.location.href = "tel://400-8676768";
      }
      if (data.type === "QQ") {
        window.location.href = "tencent://message/?uin=2376201058";
      }
    },
    //进入聊天界面清除当前用户的未读数
    setReadMessage(client, user) {
      this.$api
        .post("sys/SysChatMessages/readMessage", {
          clientId: client || undefined,
          userId: user || undefined,
        })
        .then(() => { });
    },
    showDialog() {
      this.show = true;
      this.unReadNumbers = 0;
      if (!this.firstLoad) {
        this.firstLoad = true;
        this.fromNow = [];
        this.list = []
        this.loadNext();
      }
    },
    reSocket() {
      this.ws.connect();
    },
    newSocket() {
      this.ws.close();
      this.initSocket();
    },
    initSocket() {
      this.ws = new WebSocket(
        `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.host
        }/taoshu-data/chat`
      );

      this.ws.onopen = () => {
        this.ws.send(
          JSON.stringify({
            eventType: "REGISTER",
            clientId: this.clientId,
          })
        );
        const token = this.$help.token.get();
        if (token) {
          this.ws.send(
            JSON.stringify({
              eventType: "LOGIN",
              token: token.replace("Bearer ", ""),
            })
          );
          this.$help.socket.ws = this.ws;
        }
      };
      this.ws.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (data.eventType === "PING") {
          this.ws.send(
            JSON.stringify({
              eventType: "PING",
              content: "ping",
            })
          );
        } else if (data.eventType === "CHAT_MESSAGE" || data.eventType === "TRANSFER_MESSAGE") {
          this.getMsg(data);
        } else if (data.eventType === "WITHDRAW_MESSAGE") {
          //撤回消息
          this.getWithdraw(data);
        } else if (data.eventType === "LOGIN") {  //继承人工客服在线时间
          const time = data.ttl * 1000
          help.setCookie("myCookie", "someValue", time, new Date())
        }
      };

      this.ws.onclose = () => {
        this.ws = null;
        this.initSocket();
      };
    },
    //撤回消息
    getWithdraw(data) {
      this.list = this.list.map((item) => {
        if (item.id === data.id) {
          this.$set(item, "withdraw", 1);
        }
        return item;
      });
    },
    // 判断是不是自己发送的
    sendIsMe(list) {
      return list.map((item) => this.sendIsMeDetail(item));
    },
    sendIsMeDetail(item) {
      item.isMe = this.$store.state.user.id === item.fromUserId || this.clientId === item.fromClientId;
      item.userName = item.fromUserId ? item.fromUser.userName : '自己'
      item.time = item.time || item.voiceTime
      item.newTime = item.voiceTime || 0
      item.isPlay = item.isPlay || false
      item.isDisplayText = item.isDisplayText || false
      if (item.quoteMessage && JSON.parse(item.quoteMessage).messageType === 'VOICE') {
        const obj = JSON.parse(item.quoteMessage)
        obj.time = obj.time || obj.voiceTime
        obj.newTime = obj.voiceTime || 0
        obj.isPlay = obj.isPlay || false
        item.quoteMessage = JSON.stringify(obj)
      }
      return item;
    },

    // 设置滚动到底部
    setScrollBottom() {
      this.$nextTick(() => {
        const el = this.$refs.history;
        el.scrollTop = el.scrollHeight;
      });
    },
    //给多问题加点击事件
    addClickEventsToLinks() {
      const linkContainer = this.$refs.RICHTEXT;
      if (linkContainer && linkContainer.length !== 0) {
        for (let i = 0; i < linkContainer.length; i++) {
          const arrList = linkContainer[i].querySelectorAll('div')
          if (arrList && arrList.length !== 0) {
            arrList[1].querySelectorAll('a').forEach(link => {
              if (link.title === '转人工客服咨询') {
                link.removeEventListener('click', this.onTransfer);
                link.addEventListener('click', this.onTransfer);
              } else {
                link.removeEventListener('click', this.handleLinkClick);
                link.addEventListener('click', this.handleLinkClick);
              }

            });
          } else {
            const arr = linkContainer[i].querySelectorAll('a')[0]
            if (arr.title === '人工客服') {
              arr.removeEventListener('click', this.onTransfer);
              arr.addEventListener('click', this.onTransfer);
            }
          }
        }
      }
    },
    //实现多问题发送
    handleLinkClick(event) {
      this.txt = event.target.title
      this.sendMsg();
    },
    getMsg(data) {
      if (!this.show) {
        this.unReadNumbers += 1;
      } else {
        this.ws.send(
          JSON.stringify({
            eventType: "READ_INFO",
            id: data.id,
          })
        );
      }
      //判断人工介入并且serviceType为2的去加入cookie
      if (help.getCookie('myCookie') || (data.eventType === "TRANSFER_MESSAGE" && data.serviceType === '2')) {
        // 使用函数设置一个半小时后过期的Cookie
        const date = new Date();
        const time = 5 * 60 * 1000
        help.setCookie("myCookie", "someValue", time, date)
        this.isTransfer = false
      }

      this.list.push(this.sendIsMeDetail(data));
      this.setScrollBottom();
      this.$nextTick(() => {
        this.addClickEventsToLinks()
      })
      this.sendLoading = false;
      this.imgPopup.loading = false;
      this.imgPopup.show = false;
    },
    // 加载下一页
    loadNext(isLoadMore = false) {
      this.loading = true;
      const param = {
        clientId: this.clientId || undefined,
        userId: this.$store.state.user.id || undefined,
      };
      if (this.list.length) {
        param.sendTime = this.list[0].sendTime;
      }
      if (param.userId) {
        delete param.clientId
      }
      this.$api
        .post("sys/SysChatMessages/queryReadChatMessageList", param)
        .then((res) => {
          // 判断是不是最后一页
          if (res.data.length < 10) {
            this.finished = true;
          }
          let endTime;
          if (res.data && res.data.length > 0) {
            this.list = this.sendIsMe(
              (res.data || []).filter(
                (item) => !this.list.find((i) => i.id === item.id)
              )
            )
              .concat(this.list)
              .sort((a, b) => {
                let timeA = new Date(a.sendTime).getTime();
                let timeB = new Date(b.sendTime).getTime();
                return timeA - timeB;
              });
            endTime = this.list[this.list.length - 1].sendTime || "";

            this.list = this.list.map((item) => {
              if (item.messageType === 'VOICE') {
                item.time = item.time || item.voiceTime
                item.newTime = item.voiceTime || 0
                item.isPlay = item.isPlay || false
                item.isDisplayText = item.isDisplayText || false
              }
              if (item.quoteMessage && JSON.parse(item.quoteMessage).messageType === 'VOICE') {
                const obj = JSON.parse(item.quoteMessage)
                obj.time = obj.voiceTime
                item.quoteMessage = JSON.stringify(obj)
              }
              return item;
            })
          }
          this.ws.send(
            JSON.stringify({
              eventType: "AUTOREPLY_MESSAGE",
              toClientId: this.clientId || "",
              toUserId: this.$store.state.user.id || "",
              sendTime: endTime || "",
            })
          );
          this.$nextTick(() => {
            this.addClickEventsToLinks()
          })
        })
        .finally(() => {
          if (isLoadMore) {
            this.$nextTick(() => {
              const el = this.$refs.history;
              let currentBottomScroll = el.scrollHeight - el.scrollTop;
              el.scrollTop = el.scrollHeight - currentBottomScroll;
            });
          } else {
            this.setScrollBottom();
          }
          this.loading = false;
        });
    },

    addFace(item) {
      this.txt += item;
    },

    // 输入框的粘贴事件
    pasteContent(e) {
      const pasteData = e.clipboardData || window.e.originalEvent.clipboardData;

      if (pasteData && pasteData.getData("Text")) {
        // 如果复制的是文字，则不做处理
      } else {
        const items = pasteData.items;
        if (items.length > 0 && items[0].kind === "file") {
          const item = items[0];
          if (/^image\/[jpeg|png|gif|jpg]/.test(item.type)) {
            const file = e.clipboardData.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // 监听读取完成
            reader.onload = () => {
              this.uploadBase64(reader.result);
            };
          }
        }
      }
    },
    //切换发送方式
    handleCommand(command) {
      if (this.isEnter != command) {
        this.isEnter = command
        this.$help.store.set('sendingMethod', command)
      }
    },
    // 输入框按回车的事件
    inputEnter(e) {
      if (this.isEnter === 'ctrlEnter' && e.ctrlKey) {
        e.preventDefault();
        this.sendMsg();
      }
      if (this.isEnter === 'Enter') {
        if (e.ctrlKey) {
          return this.txt += '\n';
        }
        e.preventDefault();
        this.sendMsg();
      }
    },

    // 粘贴的图片进行上传
    uploadBase64(data) {
      this.imgPopup.show = true;
      this.imgPopup.url = data;
      this.imgPopup.loading = false;
    },

    confirmImg() {
      this.imgPopup.loading = true;
      // this.sendLoading = true
      qiniu
        .uploadByBase64(this.imgPopup.url)
        .then((res) => {
          this.sendImg(res.url);
        })
        .catch(() => {
          this.sendLoading = false;
        });
    },

    // 选择图片上传
    goUploadImg() {
      this.typeFile = 'img'
      this.$refs.upload.$refs["upload"].$children[0].$refs.input.click();
    },
    // 选择文件上传
    goUploadFile() {
      this.typeFile = 'file'
      this.$refs.upload.$refs["upload"].$children[0].$refs.input.click();
    },
    // 发送文件消息
    sendFile(file) {
      this.ws.send(
        JSON.stringify({
          eventType: "CHAT_MESSAGE",
          messageType: "FILEURL",
          clientMsgId: this.$help.getUuid(),
          content: file,
          sendClientType: "pc",
          serviceType: help.getCookie('myCookie') ? '2' : '1',
        })
      );
      this.txt = "";
      this.setScrollBottom();
    },
    // 发送图片消息
    sendImg(file) {
      this.ws.send(
        JSON.stringify({
          eventType: "CHAT_MESSAGE",
          messageType: "IMAGE",
          clientMsgId: this.$help.getUuid(),
          content: file,
          sendClientType: "pc",
          serviceType: help.getCookie('myCookie') ? '2' : '1',
        })
      );
      this.txt = "";
      this.setScrollBottom();
    },
    // 发送语音
    sendVoice(file, time) {
      this.ws.send(
        JSON.stringify({
          eventType: "CHAT_MESSAGE",
          messageType: "VOICE",
          clientMsgId: this.$help.getUuid(),
          content: file,
          voiceTime: time,
          sendClientType: "pc",
          serviceType: help.getCookie('myCookie') ? '2' : '1',
        })
      );
      this.setScrollBottom();
    },
    // 发送文字消息
    sendMsg() {
      if (this.sendLoading) {
        return false;
      }
      if (!this.txt) {
        return false;
      }
      let falg = ['人工', '人工客服', '转人工客服'].includes(this.txt)
      if (falg) {
        if (help.getCookie('myCookie')) {
          return this.$store.commit('error', '您已转人工，请勿重复操作')
        }
        this.goArtificial()
      } else {
        this.ws.send(
          JSON.stringify({
            eventType: "CHAT_MESSAGE",
            messageType: "TEXT",
            clientMsgId: this.$help.getUuid(),
            content: this.txt,
            quoteId: this.quoteObj.id || "",
            sendClientType: "pc",
            serviceType: help.getCookie('myCookie') ? '2' : '1',
          })
        );
      }
      this.txt = "";
      this.quoteObj = {};
      this.setScrollBottom();
    },
    //获取游客名称
    getTouristName(clientId) {
      return `游客${clientId.substring(clientId.length - 5)}`;
    },
  },
};
</script>