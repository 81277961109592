import './assets/css/reset.scss'
import './assets/css/element.scss'
import './assets/css/iconfont.scss'
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import directiveDialog from '@/directive/dialogDrag'
import dragDiv from '@/directive/dragDiv'
import store from './config/store'
import router from './config/router'
import filters from './util/filter'
import api from './util/api'
import help from './util/help'
import variable from './util/variable'



// 组件
import TsTable from '@/components/TsTable';
import TsYearSelect from '@/components/TsYearSelect';
import TsError from '@/components/TsError';
import TsEmpty from '@/components/TsEmpty';
import TsTableOperate from '@/components/TsTableOperate';
import TsTableDetail from '@/components/TsTableDetail';
import TsDrawer from '@/components/TsDrawer';
import TsFace from '@/components/TsFace.vue';
import ClearDrawer from '@/components/ClearDrawer';
import Gov from '@/components/Gov';
import TsSearch from '@/components/TsSearch';
import FeedDialog from '@/components/FeedDialog';
import TsUpload from '@/components/TsUpload.vue';
import TsDialog from '@/components/TsDialog';
import TsAvatar from '@/components/TsAvatar.vue';
import TsUeditor from '@/components/ts-ueditor';
import CustomerService from '@/components/CustomerService.vue';
import ConvenientFiltering from '@/components/ConvenientFiltering.vue';  //便捷筛选
import MultiLevelJump from '@/components/MultiLevelJump.vue';  //多级跳转
import TsSearchLeft from '@/components/TsSearchLeft.vue';  //表格左侧筛选
import DrawerContent from '@/components/DrawerContent.vue';  
// import CanvasData from '@/components/CanvasData';

Vue.config.productionTip = false

Vue.use(ElementUI, {size: 'mini', zIndex: 1001})

Vue.component('TsTable', TsTable);
Vue.component('ClearDrawer', ClearDrawer)
Vue.component('TsUpload', TsUpload);
Vue.component('TsFace', TsFace);
Vue.component('TsYearSelect', TsYearSelect);
Vue.component('TsError', TsError);
Vue.component('TsAvatar', TsAvatar);
Vue.component('TsEmpty', TsEmpty);
// Vue.component('CanvasData', CanvasData);
Vue.component('TsTableDetail', TsTableDetail);
Vue.component('TsTableOperate', TsTableOperate);
Vue.component('TsDrawer', TsDrawer);
Vue.component('TsDialog', TsDialog);
Vue.component('Gov', Gov);
Vue.component('TsSearch', TsSearch);
Vue.component('FeedDialog', FeedDialog);
Vue.component('TsUeditor', TsUeditor);
Vue.component('CustomerService', CustomerService);
Vue.component('ConvenientFiltering', ConvenientFiltering);
Vue.component('MultiLevelJump', MultiLevelJump);
Vue.component('TsSearchLeft', TsSearchLeft);
Vue.component('DrawerContent', DrawerContent);

Vue.directive(directiveDialog.name, directiveDialog);
Vue.directive(dragDiv.name, dragDiv);

Vue.prototype.$api = api
Vue.prototype.$help = help
Vue.prototype.$variable = variable
Vue.prototype.$fullOption = {
  start: 0,
  movingFlag: true,
  duration: 0
}

Vue.prototype.$showDialog = CustomerService.methods.showDialog;

Vue.prototype.goSearchCom = (com_name) => {
  const { href } = router.resolve({
    path: `/drug/${help.encryption(com_name)}`,
  });
  window.open(href, "_blank");
}
Vue.prototype.goSearchComp = (comp_name) => {
  const { href } = router.resolve({
    path: `/corp/${help.encryption(comp_name)}`,
  });
  window.open(href, "_blank");
}

// filter
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


// 连接socket
// help.socket.connect = () => {
//   if (!help.token.get()) {
//     return false
//   }
//   const ws = new WebSocket(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/taoshu-data/page/ws?clientType=IPC&token=${help.token.get().replace('Bearer ', '')}`)

//   help.socket.ws = ws
//   return ws
// }
// if (process.env.NODE_ENV !== 'development') {
//   help.socket.connect()
// }

help.socket.send = vueObj => {
  // 修改标题
  if (!vueObj.pageTitleSelf) {
    help.setTitle(vueObj.pageName || '淘数网')
  }

  if (!help.token.get()) {
    return false
  }
  help.socket.msgList.push(JSON.stringify({
    visitId: help.getVisitId(vueObj.visitId),
    pageId: vueObj.pageId,
    pageName: vueObj.pageName,
    url: vueObj.$route.meta.path || vueObj.$route.path,
  }))
  if (help.socket.ws && help.socket.ws.readyState === 1) {
    help.socket.exec()
  }
}
help.socket.exec = () => {
  const list = help.deepClone(help.socket.msgList)
  help.socket.msgList = []

  // 开始发消息
  list.forEach(item => {
    help.socket.ws.send(item)
  })
}


new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
