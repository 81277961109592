import variable from './variable';
import api from './api';
import router from '@/config/router'
import storeBase from '@/config/store'


// region 日期格式化
function timeFormat(time, fmt) {
    const o = {
        "M+": time.getMonth() + 1, //月份
        "d+": time.getDate(), //日
        "h+": time.getHours(), //小时
        "m+": time.getMinutes(), //分
        "s+": time.getSeconds(), //秒
        "q+": Math.floor((time.getMonth() + 3) / 3), //季度
        "S": time.getMilliseconds() //毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt || '1970-01-01 00:00:00'
}
Date.prototype.Format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
    return timeFormat(this, fmt)
}
Date.prototype.FormatShort = function () {
    return timeFormat(this, 'yyyy-MM-dd')
}
String.prototype.Format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
    if (isNaN(this) && !isNaN(Date.parse(this))) {
        return timeFormat(new Date(this), fmt)
    }
}
String.prototype.FormatShort = function () {
    if (isNaN(this) && !isNaN(Date.parse(this))) {
        return timeFormat(new Date(this), 'yyyy-MM-dd')
    }
}
const connectKey = '*#06#';
function getRealName(name) {
    if (name.indexOf(connectKey) !== -1) {
        return name.split(connectKey)[0]
    }
    return name
}

const colors = ['#5171DF', '#7CC684', '#DC4D4D', '#3CB7DB', '#928CF9', '#E796FF', '#C0D97F', '#AA8073', '#5171DF', '#DC4D4D', '#999999']
// endregion
// 深拷贝
const clone = (obj) => {
    let o
    if (typeof obj === 'object') {
        if (obj === null) {
            o = null;
        } else {
            if (obj instanceof Array) {
                o = [];
                for (let i = 0, len = obj.length; i < len; i++) {
                    o.push(clone(obj[i]));
                }
            } else if (obj instanceof Date) {
                o = new Date(obj)
            } else {
                o = {};
                for (let j in obj) {
                    o[j] = clone(obj[j]);
                }
            }
        }
    } else {
        o = obj;
    }
    return o;
}

const store = {
    get(key) {
        const value = localStorage.getItem(key)
        if (value) {
            try {
                const value_json = JSON.parse(value)
                if (typeof value_json === 'object') {
                    return value_json
                } else if (typeof value_json === 'number') {
                    return value_json
                }
            } catch (e) {
                return value
            }
        } else {
            return false
        }
    },
    set(key, value) {
        localStorage.setItem(key, value)
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear();
    }
}

/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
function accAdd(arg1, arg2) {
    arg1 = parseFloat(arg1)
    arg2 = parseFloat(arg2)
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
}

/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1减去arg2的精确结果
 **/
function accSub(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
function accMul(arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    }
    catch (e) { }
    try {
        m += s2.split(".")[1].length;
    }
    catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

/**
 ** 除法函数，用来得到精确的除法结果
 ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 ** 调用：accDiv(arg1,arg2)
 ** 返回值：arg1除以arg2的精确结果
 **/
function accDiv(arg1, arg2) {
    if (arg2 == 0) {
        return 0
    }
    var t1 = 0, t2 = 0, r1, r2;
    try {
        t1 = arg1.toString().split(".")[1].length;
    }
    catch (e) { }
    try {
        t2 = arg2.toString().split(".")[1].length;
    }
    catch (e) { }
    r1 = Number(arg1.toString().replace(".", ""));
    r2 = Number(arg2.toString().replace(".", ""));
    return (r1 / r2) * Math.pow(10, t2 - t1);
}

function guid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function getExportStatus(id, cb) {
    api.get(`sys/sysExportRecord/querySysExportRecord`, { id })
        .then(res => {
            if (res.data.status === '3') {
                // 成功
                setTimeout(() => {
                    cb(true)
                    window.open(res.data.file,'_blank')
                }, 2000)
            } else if (res.data.status === '4') {
                // 失败
                cb(res.data.errorMsg)
            } else {
                setTimeout(() => {
                    getExportStatus(id, cb)
                }, 1000)
            }

        })
}

function exportInfo(txt = '确定导出吗', apiUrl, query = {}) {
    storeBase.commit('confirm', {
        content: txt,
        callback: (next, back) => {
            api.post(apiUrl, query)
                .then(res => {
                    if (!['N0001', '00000'].includes(res.code)) {
                        next()
                        storeBase.commit('error', res.message)
                    } else if (res.code === 'N0001') {
                        next()
                        if (query['params']) {
                            query.model = true
                        }
                        exportInfo(res.message, apiUrl, query)
                    } else {
                        getExportStatus(res.data.id, data => {
                            if (data === true) {
                                next()
                                storeBase.commit('success', '导出成功')
                            } else {
                                back()
                                storeBase.commit('error', data)
                            }
                        })
                    }
                })
                .catch(back)
        },
    })
}

function calcQuarterData(data, field) {
    return data.filter(item => variable.quarter.map(item => item.value).includes(item[field]))
}

function creatArr(str, num = 5) {
    let start = 0
    let end = num
    let arr = [] //保存每次截取后的字符串
    let counts = Math.ceil(str.length / num) //循环截取的次数,向上取整

    for (let i = 0; i < counts; i++) {
        let newStr = str.substring(start, end)
        arr.push(newStr)
        start = end
        end += num
    }
    return arr
}

export default {
    // 深拷贝
    deepClone: clone,

    // 生成uuid
    guid,

    accAdd,
    accDiv,
    accMul,
    accSub,

    // 生成通用列
    generalCols(cols) {
        return cols.map(col => {
            return Object.assign({
                id: guid(),
                detailView: "是",
                listView: "是",
                listQueryModel: 0,
                width: 80,
            }, col)
        })
    },

    // 合并参数
    joinObj() {
        const obj = {}
        for (let i = 0; i < arguments.length; i++) {
            Object.assign(obj, arguments[i])
        }
        return obj
    },

    // 翻页通用参数
    getPage() {
        return {
            page: 1,
            pageSize: 20,
            // sidx: '',
            // sord: '',
            total: 0,
        }
    },

    getClientId() {
        let clientId = store.get('clientId')
        if (!clientId) {
            clientId = new Date().getTime() + Math.random().toString(36).substr(2)
            store.set('clientId', clientId)
        }
        return clientId
    },
    removeClientId() {
        store.remove('clientId')
    },

    // storage操作
    store,
    token: {
        get() {
            return store.get('taoshuToken') || ''
        },
        set(val) {
            store.set('taoshuToken', val)
        },
        remove() {
            store.remove('taoshuToken')
            store.remove('clientId')
        },
    },

    goLogin() {
        router.push(`/login?url=${encodeURIComponent(window.location.href)}`)
        // setTimeout(() => {
        //     window.location.reload()
        // }, 10)
    },

    setTitle(title) {
        document.title = title;
        let userAgent = window.navigator.userAgent.toLowerCase();
        let isiOS = userAgent.indexOf('applewebkit') >= 0;
        let isWechat = userAgent.indexOf('micromessenger') >= 0;
        if (isiOS && isWechat) {
            let iframe = document.createElement('iframe');
            iframe.src = 'https://www.baidu.com/favicon.ico';
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.remove();
                }, 0)
            }
        }
    },

    // 首页通用表格的获取列
    getCommonTable(param) {
        param.type = 'table';
        param.list = [];
        param.loading = false;
        param.pageParam = {
            page: 1,
            pageSize: variable.basePageSize,
            total: 0,
        };
        param.error = ''
        param.empty = false
        param.sortParam = {};
        param.cols = param.cols || [];
        param.cols = param.cols.map(item => {
            !item.listView && (item.listView = '是');
            return item
        })
        return param;
    },

    renderMulLine(el, list = [], field = 'treatSmall') {
        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption({
            tooltip: {
                trigger: 'axis',
                // backgroundColor: '#fff',
                // textStyle: {
                //     color: '#333' //设置文字颜色
                // },
                // extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
                // formatter: function (params) {
                //     return `${params[0].axisValue}${field === 'year' ? '年' : ''}<br/><i class="canvas-point" style="background: ${params[0].color}"></i>${params[0].seriesName}：${params[0].value}%`;
                // },
                // axisPointer: {
                //     animation: false,
                // },
            },
            xAxis: [{
                type: 'category',
                data: variable.years.map(item => item.value),
            },],
            yAxis: [{
                type: 'value',
                scale: true,
                axisTick: { show: false },
                axisLine: { show: false },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
            },],
            series: list.map(item => {
                const obj = {
                    name: item[field],
                    type: 'line',
                    smooth: true,
                    data: [],
                    connectNulls: true,
                }

                variable.years.forEach(year => {
                    let val = item[`amount${year.value}` || '']
                    if (val == 0) {
                        val = ''
                    }
                    obj.data.push(val)
                })

                return obj
            })
        })

        return myChart
    },
    renderLine(el, data, field = 'year', option = {}) {
        const names = ['占比']

        if (field === 'quarter') {
            data = calcQuarterData(data, 'value')
        }

        data = data.map(item => {
            if (item.value == 0) {
                item.value = ''
            }
            return item
        })


        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption(Object.assign({
            // width: isLong ? undefined : '50%',
            tooltip: {
                trigger: 'axis',
                backgroundColor: '#fff',
                textStyle: {
                    color: '#333' //设置文字颜色
                },
                extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
                formatter: function (params) {
                    return `${params[0].axisValue}${field === 'year' ? '年' : ''}<br/><i class="canvas-point" style="background: ${params[0].color}"></i>${params[0].seriesName}：${params[0].value}%`;
                },
                axisPointer: {
                    animation: false,
                },
            },
            color: ['#32B16C'],
            grid: {
                top: 128,
                right: 170,
                left: 170,
                bottom: 60,
                containLabel: true
            },
            legend: {
                data: names,
                top: 43,
                right: 45,
            },
            xAxis: [
                {
                    type: 'category',
                    data: data.map(item => item[field]),
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        formatter: v => `${v}%`
                    },
                    min: 0
                },
            ],
            series: [
                {
                    name: names[0],
                    type: 'line',
                    data: data,
                    smooth: true,
                    connectNulls: true,
                },
            ],
        }, option), true)

        return myChart
    },
    renderBarOnly(el, data, field = 'year', option = {}) {
        const names = [`销售额（${variable.unit}）`]

        const elObj = document.getElementById(el)
        const barWidth = 25
        const myChart = window.echarts.init(elObj)
        myChart.clear()
        myChart.setOption(Object.assign({
            // width: isLong ? undefined : '50%',
            color: colors,

            tooltip: {
                trigger: 'axis',
                backgroundColor: '#fff',
                textStyle: {
                    color: '#333' //设置文字颜色
                },
                extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
                formatter: function (params) {
                    return `${params[0].axisValue}${field === 'year' ? '年' : ''}<br/><i class="canvas-point" style="background: ${params[0].color}"></i>${params[0].seriesName}：${params[0].value}`;
                },
                axisPointer: {
                    animation: false,
                },
            },
            grid: {
                top: 40,
                right: 10,
                left: 10,
                bottom: 10,
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: data.map(item => item.name),
                    show: true,
                    axisLabel: {
                        margin: 12,
                        interval: 0,
                        lineHeight: 20,
                        fontSize: 12,
                        formatter: (item) => {
                            const str = creatArr(item)
                            if (typeof str === 'string') {
                                return str
                            } else {
                                return str.join('\n')
                            }
                        },
                    }
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    // scale: true,
                    // name: '销售额',
                    axisTick: { show: false },
                    axisLine: { show: false },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                },
            ],
            series: [
                {
                    name: names[0],
                    type: 'bar',
                    barWidth: barWidth,
                    // itemStyle: {
                    //     barBorderRadius: isCrude ? 12 : 8,
                    // },
                    data: data,
                },
            ],
        }, option), true)

        return myChart
    },
    renderBar(el, data, field = 'year', option = {}, bool = true) {
        if (field === 'quarter' && bool) {
            data = calcQuarterData(data, 'quarter')
        }

        // region 开始组装数据
        let x = []        // x轴的数据

        let amountY = []  // 柱状图的y轴
        let amountYMax = 100

        let rateY = []    // 折线图的y轴
        let rateYMin = 0
        let rateYMax = 0

        const dataMap = {}

        data.forEach(item => {
            x.push(item[field])
            const amount = parseFloat(item.amount || 0)
            amountY.push(amount)
            amountYMax = Math.max(amountYMax, amount)

            // 处理第二列
            const val = item.rate || 0
            rateY.push(val)
            rateYMin = Math.min(rateYMin, val)
            rateYMax = Math.max(rateYMax, val)


            dataMap[item[field]] = {
                amount,
                rate: val
            }
        })
        rateYMin = rateYMin < 0 ? Math.min(-100, Math.floor(rateYMin)) : 0
        // rateYMax = rateYMax < 0 ? 0 : Math.max(100, Math.ceil(rateYMax))
        rateYMax = rateYMax < 0 ? 0 : (rateYMax - (rateYMax % 5) + 5)

        rateY = rateY.map(item => {
            if (item == rateYMin) {
                item = ''
            }
            return item
        })

        // 保持金额的最大值能被5整除
        amountYMax = amountYMax - (amountYMax % 5) + 5

        // endregion

        const names = [`销售额（${variable.unit}）`, '增长率']


        const elObj = document.getElementById(el)
        const myChart = window.echarts.init(elObj)

        const barWidth = 25

        myChart.clear()
        myChart.setOption(Object.assign({
            // width: isLong ? undefined : '50%',
            color: colors,
            dataZoom: field === 'year' ? undefined : [
                {
                    zoomLock: true,
                    type: 'slider',
                    xAxisIndex: [0, 1], // 表示这个 dataZoom 组件控制 第一个 xAxis
                    startValue: 0, // 数据窗口范围的起始数值index
                    endValue: parseInt((elObj.clientWidth - 200) / (barWidth * 2.6)), // 数据窗口范围的结束数值index

                    orient: 'horizontal',
                    height: 10,
                    bottom: 10,
                    filterMode: 'none',
                    backgroundColor: '#eee',//两边未选中的滑动条区域的颜色
                    showDataShadow: false,//是否显示数据阴影 默认auto
                    showDetail: false,
                    brushSelect: false,
                },
            ],
            tooltip: {
                trigger: 'axis',
                // axisPointer: {
                //     type: 'cross',
                //     crossStyle: {
                //         color: '#999'
                //     }
                // },
                backgroundColor: '#fff',
                textStyle: {
                    color: '#333' //设置文字颜色
                },
                extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
                formatter: function (params) {
                    return `${params[0].axisValue}${field === 'year' ? '年' : ''}<br/><i class="canvas-point" style="background: ${params[0].color}"></i>${params[0].seriesName}：${params[0].value}<br/><i class="canvas-point" style="background: ${params[1].color}"></i>${params[1].seriesName}：${params[1].value}%`;
                },
                // axisPointer: {
                //     animation: false,
                // },
            },
            grid: {
                top: 30,
                left: 20,
                right: 20,
                bottom: field === 'year' ? 30 : 40,
                containLabel: true
            },
            // legend: {
            //     data: names,
            //     top: 43,
            //     right: 45,
            // },
            legend: {
                data: names,
                top: 0,
                right: 'auto',
                left: 'center'
            },
            xAxis: [
                {
                    type: 'category',
                    data: x,
                    show: true,
                    // axisLabel: field !== 'year' ? {
                    //     interval:0,
                    //     rotate:40
                    // } : {}
                    axisLabel: {
                        interval: 0,
                        formatter: (item, index) => {
                            // if (field === 'year') {
                            //     return item
                            // } else if (item) {
                            //     const arr = item.split('Q')
                            //     return `${arr[1] == 1 ? arr[0] : ''}\nQ${arr[1]}`
                            // }
                            let str = ''
                            if (index % 2 !== 0 && field === 'quarter') {
                                str += '|\n\n'
                            }

                            str += `{name|${item}}`
                            const obj = dataMap[item]
                            // str += `\n{money|¥} {amount|${obj.amount.toLocaleString()}}`
                            str += `\n{amount|${obj.amount.toLocaleString()}}`
                            str += `\n{${obj.rate > 0 ? 'red' : 'green'}|${obj.rate}%}`

                            return str
                        },
                        rich: {
                            green: {
                                fontSize: 10,
                                color: '#67C23A'
                            },
                            red: {
                                fontSize: 10,
                                color: '#DC4D4D'
                            },
                            amount: {
                                fontSize: 10,
                                color: '#999',
                                padding: [10, 0, 10, 0]
                            },
                            money: {
                                fontSize: 10,
                                color: '#999',
                            },
                            name: {
                                fontSize: 10,
                                fontWeight: 'bold',
                            },
                        }
                    }
                },
                {
                    type: 'category',
                    data: x,
                    show: false,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    // name: '销售额',
                    max: amountYMax,
                    min: 0,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    splitNumber: 5,
                    interval: amountYMax / 5,
                },
                {
                    type: 'value',
                    scale: true,
                    // name: '增长比',
                    max: rateYMax,
                    min: rateYMin,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    splitNumber: 5,
                    interval: (rateYMax - rateYMin) / 5,
                    // axisLine: {show: false},
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        formatter: v => `${v}%`
                    }
                },
            ],
            series: [
                {
                    name: names[0],
                    type: 'bar',
                    // barWidth: field === 'year' ? 30 : 8,
                    barWidth: barWidth,
                    data: amountY,
                },
                {
                    name: names[1],
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    data: rateY,
                    connectNulls: true,
                    smooth: true
                },
            ],
        }, option), true)

        return myChart
    },
    renderPie(el, data, param = {}) {
        data = data.filter(item => item.value)
        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption(Object.assign({
            tooltip: {
                trigger: 'item',
                backgroundColor: '#fff',
                textStyle: {
                    color: '#333' //设置文字颜色
                },
                formatter: item => {
                    if (item.data.spec) {
                        return `${item.data.spec}${item.data.dose}\n<br>${item.data.compName} ${item.percent}%`
                    }
                    return `${item.name}：${item.percent}%`
                },
                extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
            },
            series: [
                {
                    name: '销量占比',
                    type: 'pie',
                    radius: ['35%', '65%'],
                    // startAngle,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    data: data,
                    label: {
                        alignTo: 'edge',
                        lineHeight: 20,
                        fontSize: 11,
                        margin: 0,
                        formatter: item => {
                            if (item.data.spec) {
                                return `${item.data.spec}${item.data.dose} ¥${item.data.amount}\n{time|${item.data.compName} ${item.percent}%}`
                            } else if (item.data.amount) {
                                return `${item.name}：¥${item.data.amount} ${item.percent}%\n`
                            }
                            return `${item.name}：${item.percent}%\n`
                        },
                        rich: {
                            time: {
                                fontSize: 11,
                                color: '#999'
                            }
                        }
                    },
                    labelLayout: function (params) {
                        const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                        const points = params.labelLinePoints;
                        // Update the end point.
                        points[2][0] = isLeft
                            ? params.labelRect.x
                            : params.labelRect.x + params.labelRect.width;
                        return {
                            labelLinePoints: points
                        };
                    },
                }
            ]
        }, param.option || {}))
    },
    renderChina(el, data, handle, optionFull) {
        const yData = [];

        data.sort(function (o1, o2) {
            if (isNaN(o1.value) || o1.value == null) return -1;
            if (isNaN(o2.value) || o2.value == null) return 1;
            return o1.value - o2.value;
        });

        let max = 100
        for (let i = 0; i < data.length; i++) {
            yData.push(data[i].name);

            // 获取最大值
            max = Math.max(max, parseFloat(data[i].value))
        }
        let option = Object.assign({
            // title: {
            //     text: title,
            //     x: 'center',
            //     backgroundColor: '#fff',
            //     textStyle: {
            //         color: '#333' //设置文字颜色
            //     },
            //     extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
            // },
            tooltip: {
                show: true,
                formatter: function (params) {
                    return params.name + '：' + (params.data ? params.data['value'] : 0)
                },
                backgroundColor: '#fff',
                textStyle: {
                    color: '#333' //设置文字颜色
                },
                extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
            },
            visualMap: {
                type: 'continuous',
                text: ['', ''],
                showLabel: true,
                seriesIndex: [0],
                orient: 'horizontal',
                itemHeight: 400,
                min: 0,
                max: max,
                inRange: {
                    color: ['#EBEFFE ', '#3255CC']
                },
                textStyle: {
                    color: '#000'
                },
                top: 30,
                left: 'left',
            },
            grid: {
                right: 20,
                top: 150,
                bottom: 0,
                width: '40%'
            },
            xAxis: {
                type: 'value',
                scale: true,
                position: 'top',
                splitNumber: 4,
                boundaryGap: false,
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    margin: 2,
                    textStyle: {
                        color: '#aaa'
                    }
                }
            },
            yAxis: {
                type: 'category',
                nameGap: 16,
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#ddd'
                    }
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: '#ddd'
                    }
                },
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        color: '#999'
                    }
                },
                data: yData
            },
            dataZoom: [{
                type: 'slider',
                show: true,
                zoomLock: true,
                yAxisIndex: [0],
                right: 0,
                width: 10,
                filterMode: 'none',
                minValueSpan: 12,
                maxValueSpan: 12,
                startValue: max,
                backgroundColor: '#eee',//两边未选中的滑动条区域的颜色
                showDataShadow: false,//是否显示数据阴影 默认auto
                showDetail: false,
                brushSelect: false,
            }],
            geo: {
                roam: 'move',
                map: 'china',
                left: 0,
                right: '50%',
                zoom: 0.9,
                label: {
                    normal: {
                        show: false
                    },
                    emphasis: {
                        show: true
                    }
                },
                itemStyle: {
                    emphasis: {
                        areaColor: '#fff464'
                    }
                },
            },
            series: [
                {
                    name: '销量',
                    type: 'map',
                    roam: false,
                    geoIndex: 0,
                    label: {
                        show: false,
                    },
                    data: data
                },
                {
                    name: 'barSer',
                    type: 'bar',
                    roam: false,
                    visualMap: false,
                    zlevel: 2,
                    barMaxWidth: 20,
                    itemStyle: {
                        normal: {
                            color: '#5E7DEA'
                        },
                        emphasis: {
                            color: "#3596c0"
                        }
                    },
                    label: {
                        normal: {
                            show: false,
                            position: 'right',
                            offset: [0, 10]
                        },
                        emphasis: {
                            show: true,
                            position: 'right',
                            offset: [10, 0]
                        }
                    },
                    data: data
                },
            ]
        }, optionFull);
        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        if (handle) {
            myChart.off('click')
            myChart.on('click', function (param) {
                handle(param)
            })
        }
        myChart.setOption(option)
    },
    renderBarRotateOnly(el, data, field = 'year', option = {}) {
        const names = ['比重']
        if (field === 'quarter') {
            data = calcQuarterData(data, 'value')
        }

        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption(Object.assign({
            // width: isLong ? undefined : '50%',
            color: colors,
            dataZoom: [
                {
                    // zoomLock: true,
                    // type: 'inside',
                    yAxisIndex: [0],
                    minValueSpan: 12,
                    maxValueSpan: 12,
                    startValue: 1000000000,

                    type: 'slider',
                    show: true,
                    zoomLock: true,
                    right: 0,
                    width: 10,
                    filterMode: 'none',
                    backgroundColor: '#eee',//两边未选中的滑动条区域的颜色
                    showDataShadow: false,//是否显示数据阴影 默认auto
                    showDetail: false,
                    brushSelect: false,
                },
            ],
            tooltip: {
                trigger: 'axis',
                backgroundColor: '#fff',
                textStyle: {
                    color: '#333' //设置文字颜色
                },
                extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
                formatter: function (params) {
                    return `${params[0].axisValue}<br/><i class="canvas-point" style="background: ${params[0].color}"></i>销量：${params[0].data.amount.toLocaleString()}<br/><i class="canvas-point" style="background: ${params[0].color}"></i>${params[0].seriesName}：${params[0].value}%`;
                },
                axisPointer: {
                    animation: false,
                },
            },
            grid: {
                top: 0,
                right: 150,
                left: 0,
                bottom: 0,
                containLabel: true
            },
            yAxis: [
                {
                    type: 'category',
                    data: data.map(item => item.name),
                    show: true,
                    // axisLabel: {
                    //     margin: 2,
                    //     interval: 0,
                    //     formatter: (item) => {
                    //         const str = creatArr(item, 12)
                    //         if (typeof str === 'string') {
                    //             return str
                    //         } else {
                    //             return str.join('\n')
                    //         }
                    //     },
                    // }
                },
            ],
            xAxis: [
                {
                    type: 'value',
                    scale: true,
                    // name: '销售额',
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        formatter: v => `${v}%`
                    },
                },
            ],
            series: [
                {
                    name: names[0],
                    type: 'bar',
                    barWidth: field === 'year' ? 20 : 8,
                    // itemStyle: {
                    //     barBorderRadius: isCrude ? 12 : 8,
                    // },
                    label: {
                        show: true,
                        position: 'right',
                        formatter: item => {
                            return `${item.data.amount}${variable.unit} ${item.value}%`
                        }
                    },
                    data: data,
                },
            ],
        }, option), true)

        return myChart
    },

    //注册受理 关系图
    renderRelationShip(el, list) {
        const myChart = window.echarts.init(document.getElementById(el))
        let childrenList = []
        if (list && list.length !== 0) {
            list.forEach(item => {
                childrenList.push({
                    name: item.name,
                    label: {
                        backgroundColor: item.backgroundColor
                    }
                })
            })
        }
        const data = {
            name: '审评内容',
            label: {
                backgroundColor: "#bbdefb",
            },
            children: childrenList
        };
        myChart.clear()
        myChart.setOption({
            // color: colorList(),

            // tooltip: {
            //     trigger: 'item',
            //     triggerOn: 'mousemove'
            // },

            series: [
                {
                    type: 'tree',
                    id: 0,
                    data: [data],
                    label: {
                        fontSize: 14,
                        color: '#000',
                        padding: [5, 8],
                        borderRadius: 5
                    },
                    left: '2%',
                    right: '2%',
                    edgeShape: 'polyline',
                    edgeForkPosition: '50%',
                    initialTreeDepth: 3,
                    orient: 'TB',
                    // symbol: 'rect',
                    lineStyle: {
                        width: 2,
                        height: 10
                    },
                    symbolOffset: [0, '-2%'],

                    // emphasis: {
                    //     focus: 'descendant'
                    // },
                    expandAndCollapse: false,
                    animationDuration: 550,
                    animationDurationUpdate: 750
                }
            ]
        })

        return myChart
    },

    // 乘以num
    parseFloat100(val = 0) {
        if (typeof val === 'string') {
            val = (parseFloat(val) || 0) * 100
        } else {
            val = val * 100
        }
        return parseFloat(val.toFixed(2))
    },

    // 轮询导出接口
    getExportStatus,
    exportInfo,

    getCanvasSeries(canvasData) {
        const color1 = '#006acc';
        const color2 = '#ff7d18';
        const color3 = '#10a050';

        let categories = [
            {
                name: '公司',
                itemStyle: {
                    color: color1
                }
            },
            {
                name: '董事',
                itemStyle: {
                    color: color2
                }
            },
            {
                name: '董事1',
                itemStyle: {
                    color: color3
                }
            }
        ];
        return [{
            type: 'graph',
            layout: 'force',
            symbolSize: 58,
            draggable: true,
            roam: 'move',
            focusNodeAdjacency: false,
            categories: categories,
            repulsion: 10,
            edgeSymbol: ['', 'arrow'],
            // edgeSymbolSize: [80, 10],
            edgeLabel: {
                normal: {
                    show: false,
                }
            },
            label: {
                show: true,
                formatter(x) {
                    return `${getRealName(x.data.name)}${x.data.qty ? '/' + x.data.qty : ''}`
                },
                color: '#000000',
                backgroundColor: '#e2f0da',
                padding: [5, 10],
            },
            force: {
                repulsion: 800,
                edgeLength: 80
            },
            data: canvasData.nodes,
            links: canvasData.links
        }]
    },

    getUrlIndex(type, size = 12, query = {}, visitId) {
        const queryParam = {
            params: { type },
            page: Object.assign({
                pageNo: 1,
                pageSize: size,
            }, query)
        }
        return api.post('sys/sysHotUrl/querySysHotUrlPage', queryParam, { 'Visit-Id': this.getVisitId ? this.getVisitId(visitId) : undefined })
            .then(res => {
                if (res.code === variable.SUCCESS_CODE) {
                    return res
                } else {
                    return { data: { records: [] } }
                }
            })
    },
    openUrl(data) {
        window.open(data.url)
    },

    formatToHump(value) {
        return value.replace(/\_(\w)/g, (all, letter) => {
            return letter.toUpperCase();
        })
    },

    // 解密
    decrypt(txt) {
        return decodeURIComponent(txt)
    },

    // 加密
    encryption(txt) {
        return encodeURIComponent(txt)
    },

    strToFace(strObj) {
        const patt = /&#\d+;/g;
        const arr = strObj.match(patt) || [];

        let H;
        let L;
        let code;

        for (let i = 0; i < arr.length; i += 1) {
            code = arr[i];
            code = code.replace('&#', '').replace(';', '');
            // 高位
            H = Math.floor((code - 0x10000) / 0x400) + 0xD800;
            // 低位
            L = ((code - 0x10000) % 0x400) + 0xDC00;
            code = `&#${code};`;
            const s = String.fromCharCode(H, L);
            strObj = strObj.replace(code, s);
        }
        return strObj;
    },

    // 金额转大写
    amountToChinese(money) {
        let cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
        let cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
        let cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
        let cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
        let cnInteger = '整';	//整数金额时后面跟的字符
        let cnIntLast = '元';	//整数完以后的单位
        //最大处理的数字
        let maxNum = 999999999999999.9999;
        let integerNum;	 //金额整数部分
        let decimalNum;	 //金额小数部分
        //输出的中文金额字符串
        let chineseStr = '';
        if (money == '') { return ''; }

        money = parseFloat(money);
        if (money >= maxNum) {
            //超出最大处理数字
            return '超出最大处理数字';
        }
        if (money == 0) {
            chineseStr = cnNums[0] + cnIntLast + cnInteger;
            return chineseStr;
        }
        //四舍五入保留两位小数,转换为字符串
        money = Math.round(money * 100).toString();
        integerNum = money.substr(0, money.length - 2);
        decimalNum = money.substr(money.length - 2);
        //获取整型部分转换
        if (parseInt(integerNum, 10) > 0) {
            var zeroCount = 0;
            var IntLen = integerNum.length;
            for (var i = 0; i < IntLen; i++) {
                var n = integerNum.substr(i, 1);
                var p = IntLen - i - 1;
                var q = p / 4;
                var m = p % 4;
                if (n == '0') {
                    zeroCount++;
                } else {
                    if (zeroCount > 0) {
                        chineseStr += cnNums[0];
                    }
                    //归零
                    zeroCount = 0;
                    chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                }
                if (m == 0 && zeroCount < 4) {
                    chineseStr += cnIntUnits[q];
                }
            }
            chineseStr += cnIntLast;
        }
        //小数部分
        if (decimalNum != '') {
            let decLen = decimalNum.length;
            for (let i = 0; i < decLen; i++) {
                let n = decimalNum.substr(i, 1);
                if (n != '0') {
                    chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                }
            }
        }
        if (chineseStr == '') {
            chineseStr += cnNums[0] + cnIntLast + cnInteger;
        } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
            chineseStr += cnInteger;
        }
        return chineseStr;
    },

    socket: {
        ws: null,
        msgList: [],
        connect() { },
        send() { },
        exec() { },
    },
    getVisitId(id) {
        if (!id) {
            return undefined
        } else if (storeBase.state.user.id) {
            return `${storeBase.state.user.userCode}-${id}`
        } else {
            return `${id}`
        }
    },

    getUuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    //清除cookie
    deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },
    //获取cookie
    getCookie(name) {
        let cookieArr = document.cookie.split(";");
        for (let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split("=");
            if (name === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    },
    //设置cookie
    setCookie(name, value, minutes, time) {
        let expires = "";
        if (minutes) {
            const date = time
            date.setTime(Date.parse(date) + minutes); // 转换分钟为毫秒
            expires = "; expires=" + date.toUTCString(); // 使用UTC字符串格式表示过期时间
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/"; // path=/使得Cookie在整个网站都可用
    },
}